import React from 'react';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href=".">Pwar.dev</a>
          </div>
          <div className="social">
            
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Our website is coming soon...</h1>
            <p>Shoot us an email if you're curious.</p>
          </div>
          <a href="mailto:pwar@pwar.dev">
            <div className="cta">Send us an email</div>
          </a>
        </div>
        <div className="footer">
        </div>
      </div>
    );
  }
}

export default App;